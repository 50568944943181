import { RouterView, type RouteRecordRaw } from 'vue-router'

import { getHelpCenterUrl } from '@/common/services/help-center-url.service'
import i18n from '@/application/i18n/i18n'
import ShippingRulesAnalytics from '@/features/shipping-rules/utils/shipping-rules.analytics'
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle'

export const SETTINGS_SHIPPING_RULES_ROUTE = 'rules'
export const SETTINGS_SHIPPING_RULES_LIST_ROUTE = 'rules.list'
export const SETTINGS_SHIPPING_RULES_IMPORT_ROUTE = 'rules.import'

export const RULE_ID_QUERY_PARAM = 'rule_id'
export const RULE_RECOMMENDATION_ID_QUERY_PARAM = 'rule_recommendation_id'

export default {
  name: SETTINGS_SHIPPING_RULES_ROUTE,
  path: '/shipping/rules',
  redirect: { name: SETTINGS_SHIPPING_RULES_LIST_ROUTE },
  component: RouterView,
  children: [
    {
      name: SETTINGS_SHIPPING_RULES_LIST_ROUTE,
      path: '/shipping/rules',
      component: () => import('@/features/shipping-rules/pages/ShippingRuleListPage.vue'),
      meta: {
        pageTitle: () => i18n.t('Shipping rules'),
        permissions: ['manage_rules'],
        parent: 'shipping-rules',
      },
      alias: ['/settings/rules/rules'],
    },

    {
      name: SETTINGS_SHIPPING_RULES_IMPORT_ROUTE,
      path: '/shipping/rules/import',
      component: () => import('@/features/shipping-rules/pages/ShippingRuleImportPage.vue'),
      meta: {
        pageTitle: () => i18n.t('Shipping rule import'),
        permissions: ['manage_rules'],
        buttons: [
          {
            id: 'open-help-center',
            title: () => i18n.t('settings.shipping_rules.upload_file.sub_header'),
            name: 'Help center button',
            href: getHelpCenterUrl('import-shipping-rules', 'settings'),
            newTab: true,
            mode: 'borderless',
            icon: faInfoCircle,
            align: 'right',
            customClick: () => ShippingRulesAnalytics.openHelpCenter('Shipping rule import'),
            permissions: ['manage_rules'],
          },
        ],
        parent: 'shipping-rules',
      },
      alias: ['settings/rules/import'],
    },
  ],
} satisfies RouteRecordRaw
