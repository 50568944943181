import store from '@/common/stores/store'
import DialogsService from '@/common/services/dialogs.service.ts'
import i18n from '@/application/i18n/i18n.ts'

export const isPackGoFeatureEnabled = (): boolean => {
  return store.getters.user.features.pack_and_go
}

export const confirmResetStatusFilters = () =>
  DialogsService.confirm({
    title: i18n.t('Attention'),
    text: i18n.t('shipping.packgo.filters.alert.remove_status_filters.description'),
    buttons: {
      confirm: {
        visible: true,
        text: i18n.t('shipping.packgo.filters.alert.remove_status_filters.action.confirm'),
        className: 'ui-button ui-button--primary',
      },
      cancel: {
        visible: true,
        text: i18n.t('shipping.packgo.filters.alert.remove_status_filters.action.cancel'),
      },
    },
  })
