import type { AxiosResponse } from 'axios'

import store from '@/common/stores/store'
import type { SenderAddress } from '@/features/addresses/types/addresses.types'
import type { Order } from '@/types/orders.models'
import type { ShippingPriceItem } from '@/types/shipping.models'
import axios from '@/common/utils/axios'

export default {
  getShippingPrices(fromCountryCode: string, carrierCode: string, destinationCountryCode: string) {
    const params = new URLSearchParams()
    params.append('country_code', fromCountryCode)

    if (typeof carrierCode === 'string' && carrierCode.length > 0) {
      params.append('carrier_code', carrierCode)
    }

    if (typeof destinationCountryCode === 'string' && destinationCountryCode.length > 0) {
      params.append('to_country', destinationCountryCode)
    }

    return axios.get(`/xhr/shipping-prices/outgoing?${params.toString()}`)
  },

  getReturnPrices(toCountryCode: string, fromCountryCode: string) {
    const params = new URLSearchParams()
    params.append('country_code', toCountryCode)

    if (typeof fromCountryCode === 'string' && fromCountryCode.length > 0) {
      params.append('from_country_code', fromCountryCode)
    }

    return axios.get(`/xhr/shipping-prices/return?${params.toString()}`)
  },

  /**
   * This method calls the public API for shipping prices
   *
   * Note! This function has been moved to features/smart-shipping/api/shipping-prices.api.ts, but I've left it here as
   * the types are strange, and updating them across all of the existing usages would be a Big Effort
   */
  async getMultipleShippingPrices(orders: Order[]): Promise<AxiosResponse<MultipleShippingPricesItemResponsePayload>> {
    const payload = normalizePayload(orders)
    return axios.post(`/api/v2/multiple-shipping-prices`, payload)
  },
}

function normalizePayload(orders: Order[]): MultipleShippingPricesItemRequestPayload[] {
  const senderAddresses: SenderAddress[] = store.getters.senderAddresses

  return orders.map((order) => {
    const senderAddress = senderAddresses.find(senderAddress => senderAddress.id === order.sender_address)

    return {
      id: order.shipment_blob,
      shipping_method_id: order.shipping_method,
      weight_unit: 'gram',
      weight: Number(order.weight) * 1000,
      from_country: senderAddress?.country_iso_2 ?? '', // deliberately falling back to empty string in case a sender address doesn't exist anymore
      to_country: order.to_address.country_code,
      contract: order.contract as number ?? undefined,
      from_postal_code: senderAddress?.postal_code,
      to_postal_code: order.to_address.postal_code,
      total_insured_value: order.total_insured_value,
    } as MultipleShippingPricesItemRequestPayload
  })
}

type MultipleShippingPricesItemRequestPayload = {
  id: number
  shipping_method_id: number
  weight_unit: 'kilogram' | 'gram'
  weight: number
  from_country: string
  to_country: string
  contract?: number
  from_postal_code?: string
  to_postal_code?: string
  total_insured_value?: number
}

export type MultipleShippingPricesItemResponsePayload = {
  data: ShippingPriceItem[]
}
