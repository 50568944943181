import i18n from '@/application/i18n/i18n'
import { betaGuard } from '@/application/routes/guards/beta-guard'
import { SETTINGS_NOTIFY_TRACKING_MESSAGES_ROUTE } from '@/features/tracking-messages/routes'
import { BETA_FEATURE_TRACKING_NOTIFICATIONS } from './beta-features'
import type { RouteRecordRaw } from 'vue-router'

const SETTINGS_TRACKING_NOTIFICATIONS_ROUTE = 'tracking-notifications'
export const SETTINGS_TRACKING_NOTIFICATIONS_EMAIL_ROUTE = 'tracking-notifications.email'
export const SETTINGS_TRACKING_NOTIFICATIONS_SMS_ROUTE = 'tracking-notifications.sms'
export const SETTINGS_TRACKING_NOTIFICATIONS_WHATSAPP_ROUTE = 'tracking-notifications.whatsapp'

const EMAIL_NOTIFICATIONS: RouteRecordRaw = {
  name: SETTINGS_TRACKING_NOTIFICATIONS_EMAIL_ROUTE,
  path: '/tracking-notifications/email',
  component: () => import('@/features/tracking-notifications/pages/EmailNotificationsPage.vue'),
  meta: {
    pageTitle: () => i18n.t('features.tracking_notifications.email.title'),
    permissions: ['configure_tracking'],
    parent: 'email-notifications',
  },
}

const SMS_NOTIFICATIONS: RouteRecordRaw = {
  name: SETTINGS_TRACKING_NOTIFICATIONS_SMS_ROUTE,
  path: '/tracking-notifications/sms',
  component: () => import('@/features/tracking-notifications/pages/SmsNotificationsPage.vue'),
  meta: {
    pageTitle: () => i18n.t('features.tracking_notifications.sms.title'),
    permissions: ['configure_tracking'],
    parent: 'sms-notifications',
  },
}

const WHATSAPP_NOTIFICATIONS: RouteRecordRaw = {
  name: SETTINGS_TRACKING_NOTIFICATIONS_WHATSAPP_ROUTE,
  path: '/tracking-notifications/whatsapp',
  component: () => import('@/features/tracking-notifications/pages/WhatsappNotificationsPage.vue'),
  meta: {
    pageTitle: () => i18n.t('features.tracking_notifications.whatsapp.title'),
    permissions: ['configure_tracking'],
    parent: 'whatsapp-notifications',
  },
}

export default {
  name: SETTINGS_TRACKING_NOTIFICATIONS_ROUTE,
  path: '/tracking-notifications',
  redirect: { name: SETTINGS_TRACKING_NOTIFICATIONS_EMAIL_ROUTE },
  meta: {
    pageTitle: () => i18n.t('Tracking notifications'),
    permissions: ['configure_tracking'],
  },
  beforeEnter: betaGuard(BETA_FEATURE_TRACKING_NOTIFICATIONS, SETTINGS_NOTIFY_TRACKING_MESSAGES_ROUTE),
  children: [
    EMAIL_NOTIFICATIONS,
    SMS_NOTIFICATIONS,
    WHATSAPP_NOTIFICATIONS,
  ],
} satisfies RouteRecordRaw
