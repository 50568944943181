import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import axios from '@/common/utils/axios'

export const usePendingRequestsCountStore = defineStore('pendingRequestsCountStore', () => {
  const requestCount = ref(0)
  const hasPendingRequests = computed(() => !!requestCount.value)

  const increaseCount = () => {
    requestCount.value++
  }
  const decreaseCount = () => {
    requestCount.value--
  }

  axios.interceptors.request.use(
    (config) => {
      increaseCount()
      return config
    },

    (error) => {
      decreaseCount()
      return Promise.reject(error)
    },
  )

  axios.interceptors.response.use(
    (response) => {
      decreaseCount()
      return response
    },

    (error) => {
      decreaseCount()
      return Promise.reject(error)
    },
  )

  return { requestCount, hasPendingRequests, increaseCount, decreaseCount }
})
