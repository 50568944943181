import type { RouteRecordRaw } from 'vue-router'

import i18n from '@/application/i18n/i18n'

import { SHIPPING_BUTTONS } from '@/features/shipment-tabs/routes'

export const SHIPPING_INCOMING_ORDERS_ROUTE = 'parcels.list.orders'

export default {
  name: SHIPPING_INCOMING_ORDERS_ROUTE,
  path: '/shipping/list/orders',
  component: () => import('@/app/parcels/views/IncomingOrders.vue'),
  meta: {
    pageTitle: () => i18n.t('Incoming orders'),
    permissions: ['process_orders'],
    buttons: SHIPPING_BUTTONS,
    parent: 'shipping-orders',
  },
} satisfies RouteRecordRaw
