<template>
  <transition
    :duration="1500"
    name="sc-fade"
  >
    <div
      v-if="hasPendingRequests"
      class="app-loading-bar"
    >
      <div class="app-loading-bar__progress-bar" />
    </div>
  </transition>
</template>

<script setup lang="ts">
import { usePendingRequestsCountStore } from '@/common/stores/pending-requests-count'
import { computed } from 'vue'

const pendingRequestsCountStore = usePendingRequestsCountStore()
const hasPendingRequests = computed(() => pendingRequestsCountStore.hasPendingRequests)
</script>

<style lang="scss" scoped>
.sc-fade-enter-active,
.sc-fade-leave-active {
  --fade-transition-time: 0.5s;
}

@keyframes loading-bar {
  from {
    transform: translateX(-50vw);
  }

  to {
    transform: translateX(100vw);
  }
}

.app-loading-bar {
  height: 5px;
  overflow: hidden;
  position: fixed;
  top: var(--app-top-bar-height);
  width: 100%;
  z-index: var(--app-navigation-header-z-index);
}

.app-loading-bar__progress-bar {
  animation: loading-bar 1.5s infinite;
  animation-timing-function: ease-in-out;
  backface-visibility: hidden;
  background-color: $sc-dark-blue;
  height: 100%;
  position: absolute;
  transform: translateX(-50vw);
  transform-style: preserve-3d;
  width: 50%;
}
</style>
